import React, { useEffect } from 'react';
import Error from 'next/error';
import { useLocale } from 'src/locales';

export default function Page() {
  const locale = useLocale();
  useEffect(() => {
    setTimeout(() => {
      window.location.assign(`/${locale}`);
    }, 1000);
  }, []);
  return <Error statusCode={404} />;
}
